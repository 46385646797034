<template>
  <BaseDialog
    title="查看外部sku"
    width="50%"
    style="margin-top: -10vh"
    :dialogVisible="skuVisible"
    :hiddenFooter="true"
    @closeHandle="onClose"
    @closed="onClose"
  >
    <CommonTable :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="data"> </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </BaseDialog>
</template>

<script>
import initDataMixin from '@/mixins/initData'
export default {
  mixins: [initDataMixin],
  props: {
    productId: {
      type: Number,
      default: 0
    },
    skuVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      url: '/externaladmin/productService/customSpecificProductSkuMapping/list',
      mergeData: {
        specificProductId: this.productId
      },
      cols: [
        {
          prop: 'ownerName',
          label: '分销商名称',
          minWidth: '200'
        },
        {
          prop: 'externalSku',
          label: '外部sku',
          minWidth: '200'
        }
      ]
    }
  },
  methods: {
    onClose() {
      this.$emit('update:skuVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped></style>
